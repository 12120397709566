import React from "react";
import Autosuggest from 'react-autosuggest';
import {_getOnValueParams} from "../../../services/FireBaseClientService";
import {AvField} from "availity-reactstrap-validation";


export default class AutoSuggestStakeholder extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            nameValue: '',
            idValue: '',
            idSuggestions: [],
            nameSuggestions: [],
            stakeholders: []
        };
    }

    getSuggestionName(suggestion) {
        return suggestion.label;
    }

    getSuggestionId(suggestion) {
        return suggestion.value;
    }

    renderSuggestion(suggestion) {
        return (
            <span>{suggestion.label}</span>
        );
    }

// Teach Autosuggest how to calculate suggestions for any given input value.
    getSuggestions = value => {

    };


    onNameChange = (event, {newValue}) => {
        this.setState({
            nameValue: newValue
        });
    };

    onNameSuggestionsFetchRequested = ({value}) => {
        _getOnValueParams('/api/rest/stakeholder/autocomplete', {
            type: this.props.type,
            name: value,
            page:1,
            size:10,
            searchField: this.props.field ? this.props.field.value : '',
            searchFilter:this.props.searchFilter ? this.props.searchFilter :'',
            searchFilterValue:this.props.searchFilterValue ? this.props.searchFilterValue :''
        }, (response) => {
            this.setState({
                nameSuggestions: this.processStakeholders(response.data.stakeholders, this.props.field)
            })
        })
    };

    onNameSuggestionsClearRequested = () => {
        this.setState({
            nameSuggestions: []
        });
    };

    onNameSuggestionSelected = (event, {suggestion}) => {
        if (this.props.onNameChange) this.props.onNameChange(this.props.type == "donation_boxes" && false? suggestion.altLabel : suggestion.label)
        this.setState({
            nameValue: suggestion.label,
            idValue: suggestion.value
        });
    };

    renderNameInputComponent = inputProps => (
        <AvField {...inputProps}
                 type="input"
                 name={this.props.action + '_name'}
                 required
                 label={this.props.field.label}>
        </AvField>
    );

    componentDidMount() {
        _getOnValueParams("/api/getData", {
            query: "regForm",
            params: "\{\"type\":" + this.props.type + "\}"
        }, (response) => {
            let regForm = response.data.data.form;
            this.setState({
                regForm:regForm
            })
        })
    }


    render() {

        const {nameValue, idValue, idSuggestions, nameSuggestions} = this.state;

        const nameInputProps = {
            placeholder: this.props.field.label,
            value: nameValue,
            onChange: this.onNameChange
        };


        return (
            <>
                <Autosuggest
                    key={"name"}
                    suggestions={nameSuggestions}
                    focusInputOnSuggestionClick={false}
                    onSuggestionsFetchRequested={this.onNameSuggestionsFetchRequested}
                    onSuggestionsClearRequested={this.onNameSuggestionsClearRequested}
                    onSuggestionSelected={this.onNameSuggestionSelected}
                    renderInputComponent={this.renderNameInputComponent}
                    getSuggestionValue={this.getSuggestionName}
                    renderSuggestion={this.renderSuggestion}
                    inputProps={nameInputProps}
                />
                <div style={{display: "none"}}>

                    <AvField type="input"
                             name={this.props.action + '_id'}
                             required
                             value={idValue ? idValue : ""}
                             label={"Stakeholder Id"}>
                    </AvField>
                </div>
            </>
        );
    }

    processStakeholders(stakeholders, field) {
        let suggestions = [];
        stakeholders && stakeholders.filter(stake => {
            let found = false;
            let length = this.props.recordedStakeholder ? this.props.recordedStakeholder.length : 0;
            for (let i = 0; i < length; i++) {
                if (this.props.recordedStakeholder[i].stakeholder == stake["_Id"]) {
                    found = true;
                    break;
                }
            }
            return !found;
        }).forEach((stakeholder) => {
            let regFile = stakeholder.regFile;
            let sField = regFile && regFile.answerData.filter(a => a.name === field.value)[0];
            if (sField) {
                let suggestion;
                if (this.props.type == "donation_boxes" && false) {
                    let donationBoxSizeField = this.state.regForm && this.state.regForm.form_data.filter(a => a.label.startsWith("Size"))[0];
                    let sizeAnswer = donationBoxSizeField && regFile && regFile.answerData.filter(a => a.name === donationBoxSizeField["field_name"])[0];
                    let optionAnswer = donationBoxSizeField && donationBoxSizeField.options && sizeAnswer ? donationBoxSizeField.options.filter(option => option.key == sizeAnswer.value)[0].text : ""
                    suggestion = {value: stakeholder["_Id"], label: sField.value + (sizeAnswer ? " " + optionAnswer : ""), altLabel: sField.value  + "/" + optionAnswer};
                    console.log("suggestion", suggestion);
                } else {
                    suggestion = {value: stakeholder["_Id"], label: sField.value.toString()};
                }
                suggestions.push(suggestion);
            }
        })
        return suggestions;
    }
}